import React from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";

// pages
import Dashboard from "../pages/Dashboard";
import LoginPage from "../pages/LoginPage";

// components
import DashboardOverview from "../components/dashboard/DashboardOverview";
import JiniAssist from "../components/dashboard/JiniAssist";
import Leads from "../components/dashboard/Leads";
import Interaction from "../components/dashboard/Interaction";
import Training from "../components/dashboard/Training";
import DefaultLayout from "../layout/DefaultLayout";
import SelectProperty from "../pages/SelectProperty";
import Loginwithoutcredential from "../components/loginComponents/Loginwithoutcredential";

const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/:company_id/:comp_hash/:hotel_id/:admin_id/:auth_token/:full_name/:hotel_name"
          element={<Loginwithoutcredential />}
        />
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<DashboardOverview />} />
          <Route path="jiniAssist" element={<JiniAssist />} />
          <Route path="leads" element={<Leads />} />
          <Route path="interaction" element={<Interaction />} />
          <Route path="training" element={<Training />} />
        </Route>
        <Route path="/login" element={<Navigate to={"/"} />} />
        {/* <Route path="/select-property" element={<SelectProperty />} /> */}
        {/* <Route path="/" element={<Dashboard />}>
          <Route path="/" element={<DashboardOverview />} />
        </Route> */}
      </Routes>
    </div>
  );
};

export default AppRoutes;
