import React from "react";
import DataLoader from "../../views/loader/DataLoader";
interface viewC {
  cardInfo: {
    icon: string;
    isLoading: boolean;
    value: string;
    title: string;
  };
}
const ViewCard: React.FC<viewC> = ({ cardInfo }) => {
  const { isLoading, value, icon, title } = cardInfo;
  return (
    <div className="card-wrapper">
      <img src={icon} alt="searchIcon" />
      {isLoading ? (
        <div className="mt-2">
          <DataLoader />
        </div>
      ) : (
        <h1 className="count">{value}</h1>
      )}
      <div className="card-title">{title}</div>
      <p className="card-tag">{`(last 30 days)`}</p>
    </div>
  );
};

export default ViewCard;
