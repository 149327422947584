import { DIRECT_LOGIN } from "../actionTypes";

const initialState = ''

const LoginTypeReducer = (state = initialState, action: any) => {
    if (action.type === DIRECT_LOGIN) {
        return action.payload
    }

    else return state
};

export default LoginTypeReducer;
