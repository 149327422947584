import React, { useEffect, useState } from "react";
import PersonIcon from "../../assets/img/personIcon.png";
import SearchIcon from "../../assets/img/searchIcon.png";
import SidebarItemHeader from "../../views/headers/SidebarItemHeader";
import ViewCard from "../Cards/ViewCard";
import { ResponsiveLine } from "@nivo/line";
import jiniassistApi from "../../API/jiniassistApi";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Logout, UpdateSidebar } from "../../UtilityFunctions";
import Loader from "../../views/loader/Loader";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

const DashboardOverview = () => {
  const { company_id } = useSelector((state: RootState) => state.auth);
  UpdateSidebar("Dashboard");

  const [uniqueVisitors, setUniqueVisitors] = useState({
    isLoading: false,
    value: "",
    icon: SearchIcon,
    title: "Total Unique Visitors",
  });
  const [totalLeads, setTotalLeads] = useState({
    isLoading: false,
    value: "",
    icon: PersonIcon,
    title: "Total Leads",
  });
  const [analyticdata, setAnalyticdata] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUniqueVistors();
    individualLeadsData();
    getAnalyticsData();
  }, []);

  // Get Unique Visitors

  const getUniqueVistors = async () => {
    setUniqueVisitors({
      isLoading: true,
      value: "",
      icon: SearchIcon,
      title: "Total Unique Visitors",
    });

    let unique_visitors = await jiniassistApi.get(
      `/jiniassist/get_unique_visitors/${company_id}`
    );

    if (unique_visitors && unique_visitors.data.status === 1) {
      let val = unique_visitors.data.unique_visitors;
      if (unique_visitors.data.unique_visitors === 0) {
        setUniqueVisitors({
          isLoading: false,
          value: unique_visitors.data.message,
          icon: SearchIcon,
          title: "Total Unique Visitors",
        });
      } else {
        setUniqueVisitors({
          isLoading: false,
          value: val,
          icon: SearchIcon,
          title: "Total Unique Visitors",
        });
      }
    }
  };

  // Get Individual Leads

  const individualLeadsData = async () => {
    setTotalLeads({
      isLoading: true,
      value: "",
      icon: PersonIcon,
      title: "Total Leads",
    });

    let total_leads = await jiniassistApi.get(
      `/jiniassist/get_individual_leads/${company_id}`
    );

    if (total_leads && total_leads.data.status === 1) {
      setTotalLeads({
        isLoading: false,
        value: total_leads.data.total_leads,
        icon: PersonIcon,
        title: "Total Leads",
      });
    }
    else{
      setTotalLeads({
        isLoading: false,
        value: total_leads.data.message,
        icon: PersonIcon,
        title: "Total Leads",
      });
    }
  };

  // Get Analytics Data

  const getAnalyticsData = async () => {
    setIsLoading(true);
    try {
      let analyticdata = await jiniassistApi.get(
        `/jiniassist/get_analytics_data/${company_id}`
      );

      if (analyticdata.data) {
        setAnalyticdata(analyticdata.data);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      if (err.response.status === 400) {
        Logout();
      }
    }
  };

  const data = [
    {
      id: "Leads",
      color: "hsl(28, 70%, 50%)",
      data: analyticdata.map((item: any) => {
        return {
          x: item[0],
          y: item[1],
        };
      }),
      // data: [
      //   {
      //     x: "plane",
      //     y: 247,
      //   },
      //   {
      //     x: "helicopter",
      //     y: 276,
      //   },
      //   {
      //     x: "boat",
      //     y: 275,
      //   },
      //   {
      //     x: "train",
      //     y: 59,
      //   },
      //   {
      //     x: "subway",
      //     y: 188,
      //   },
      //   {
      //     x: "bus",
      //     y: 129,
      //   },
      //   {
      //     x: "car",
      //     y: 79,
      //   },
      //   {
      //     x: "moto",
      //     y: 19,
      //   },
      //   {
      //     x: "bicycle",
      //     y: 162,
      //   },
      //   {
      //     x: "horse",
      //     y: 290,
      //   },
      //   {
      //     x: "skateboard",
      //     y: 92,
      //   },
      //   {
      //     x: "others",
      //     y: 271,
      //   },
      // ],
    },
    {
      id: "Visitors",
      color: "hsl(100, 70%, 50%)",
      data: analyticdata.map((item: any) => {
        return {
          x: item[0],
          y: item[2],
        };
      }),
      // data: [
      //   {
      //     x: "plane",
      //     y: 77,
      //   },
      //   {
      //     x: "helicopter",
      //     y: 39,
      //   },
      //   {
      //     x: "boat",
      //     y: 203,
      //   },
      //   {
      //     x: "train",
      //     y: 127,
      //   },
      //   {
      //     x: "subway",
      //     y: 270,
      //   },
      //   {
      //     x: "bus",
      //     y: 147,
      //   },
      //   {
      //     x: "car",
      //     y: 3,
      //   },
      //   {
      //     x: "moto",
      //     y: 125,
      //   },
      //   {
      //     x: "bicycle",
      //     y: 31,
      //   },
      //   {
      //     x: "horse",
      //     y: 35,
      //   },
      //   {
      //     x: "skateboard",
      //     y: 187,
      //   },
      //   {
      //     x: "others",
      //     y: 298,
      //   },
      // ],
    },
    {
      id: "Total Bookings Attempt",
      color: "hsl(100, 70%, 50%)",
      data: analyticdata.map((item: any) => {
        return {
          x: item[0],
          y: item[3],
        };
      }),
      // data: [
      //   {
      //     x: "plane",
      //     y: 179,
      //   },
      //   {
      //     x: "helicopter",
      //     y: 167,
      //   },
      //   {
      //     x: "boat",
      //     y: 290,
      //   },
      //   {
      //     x: "train",
      //     y: 98,
      //   },
      //   {
      //     x: "subway",
      //     y: 45,
      //   },
      //   {
      //     x: "bus",
      //     y: 240,
      //   },
      //   {
      //     x: "car",
      //     y: 282,
      //   },
      //   {
      //     x: "moto",
      //     y: 54,
      //   },
      //   {
      //     x: "bicycle",
      //     y: 213,
      //   },
      //   {
      //     x: "horse",
      //     y: 201,
      //   },
      //   {
      //     x: "skateboard",
      //     y: 142,
      //   },
      //   {
      //     x: "others",
      //     y: 274,
      //   },
      // ],
    },
  ];

  const commonProperties = {
    width: 1250,
    height: 400,
    margin: { top: 30, right: 100, bottom: 100, left: 50 },
    animate: true,
    labelTextColor: "Red",
  };

  return (
    <>
      {/* <SidebarItemHeader label="Dashboard" /> */}

      <Breadcrumbs />
      <div className="hr--line"></div>

      <div className="card-container">
        <ViewCard cardInfo={uniqueVisitors} />

        <ViewCard cardInfo={totalLeads} />
      </div>

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="responsive__line__wrapper">
          <ResponsiveLine
            data={data}
            {...commonProperties}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            curve="natural"
            colors={{ scheme: "category10" }}
            yFormat=" >-.2f"
            // enableGridX={false}
            // enablePoints={false}
            pointSize={5}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            enableSlices="x"
            enableCrosshair={false}
            axisBottom={{
              // orient: 'bottom',
              tickSize: 12,
              tickPadding: 20,
              tickRotation: -21,
              // legend: "Dates",
              legendOffset: 4,
              legendPosition: "middle",
            }}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 99,
                itemWidth: 80,
                itemHeight: 35,
                itemsSpacing: 3,
                symbolSize: 15,
                symbolShape: "circle",
                itemDirection: "top-to-bottom",
                itemTextColor: "#777",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      )}
    </>
  );
};

export default DashboardOverview;
