import {
  ACTIVE_SIDEBAR_ITEM,
  ACTIVE_SIDEBAR_SUBITEM,
  UPDATE_BREADCRUMBS,
} from "../actionTypes";

export const ActiveSidebarItem = (label: string) => {
  return {
    type: ACTIVE_SIDEBAR_ITEM,
    payload: label,
  };
};

export const ActiveSidebarSubitem = (label: string) => {
  return {
    type: ACTIVE_SIDEBAR_SUBITEM,
    payload: label,
  };
};

export const UpdateBreadcrumbs = (breadcrumbs: any) => {
  return {
    type: UPDATE_BREADCRUMBS,
    payload: breadcrumbs,
  };
};
