import React, { useState, useEffect } from "react";
import SidebarItemHeader from "../../views/headers/SidebarItemHeader";
import downarrow from "../../assets/img/downarrow.png";
import SmallCard from "../Cards/SmallCard";
import Pagination from "../pagination/Pagination";
import ChatInteraction from "./ChatInteraction";
import InputSelectField from "../../views/inputfield/InputSelectField";

//import date picker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import kernelApi from "../../API/kernelApi";
import jiniassistApi from "../../API/jiniassistApi";
import CustomButton from "../../views/Buttons/CustomButton";
import Button from "../../views/Buttons/Button";
// Import Sliding Pane
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { UpdateSidebar } from "../../UtilityFunctions";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

const Interaction = () => {
  UpdateSidebar("Interaction");
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const [selectPageSize, setSelectPageSize] = useState(10);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [firstDate, setFirstDate] = useState(moment().format("YYYY-MM-DD"));
  const [currentMonth, setCurrentMonth] = useState(moment().format("MMM"));
  const [currentYear, setCurrentYear] = useState(
    parseInt(moment().format("YYYY"))
  );

  const [userInteraction, setUserInteraction] = useState<any>([]);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [selectedInteraction, setSelectedInteraction] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [openInteractionTab, setOpenInteractionTab] = useState(false);

  useEffect(() => {
    getAllHotels();
  }, []);

  const getAllHotels = async () => {
    let get_all_hotels = await kernelApi.get(
      `/hotel_admin/get_hotel_list/${company_id}`,
      {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
    );

    if (get_all_hotels && get_all_hotels.data.status === 1) {
      setSelectedHotel({
        hotel_id: get_all_hotels.data.data[0].hotel_id,
        hotel_name: get_all_hotels.data.data[0].hotel_name,
      });
      setAllHotels(get_all_hotels.data.data);
    }
  };

  const [allHotels, setAllHotels] = useState<any>([]);
  const [selectedHotel, setSelectedHotel] = useState({
    hotel_id: "",
    hotel_name: "",
  });

  const onDateChange = (date: any) => {
    let date_str = moment(date).format("YYYY-MM-DD");
    setFirstDate(date_str);
    setCurrentYear(moment(date_str).year());
    setCurrentMonth(moment(date_str).format("MMM"));
  };

  const selectHotel = (e: any) => {
    for (let hotel_data of allHotels) {
      if (hotel_data.hotel_id == e) {
        setSelectedHotel({ hotel_id: e, hotel_name: hotel_data.hotel_name });
      }
    }
  };

  const getInteractions = async () => {
    let get_user_interactions = await jiniassistApi.get(
      `/jiniassist/get_user_interactions/${selectedHotel.hotel_id}/${firstDate}`,
      {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
    );

    if (get_user_interactions && get_user_interactions.data.status === 1) {
      setUserInteraction(get_user_interactions.data.data);
      setDisplayMessage(false);
    } else {
      setDisplayMessage(true);
    }
  };

  const openInteraction = (value: any, i: any) => {
    setSelectedInteraction(JSON.parse(value.request_response));
    setSelectedUser(userInteraction[i].user_name);
    setOpenInteractionTab(true);
  };

  return (
    <div className="interation-container">
      <Breadcrumbs />
      <div className="hr--line"></div>

      <div className="leads-nav-container mt-4">
        <div className="leads-drop-continer">
          <div className="d-flex" style={{ width: "200px" }}>
            <InputSelectField
              label="Select Hotel"
              value={selectedHotel.hotel_id}
              handleChange={(e) => selectHotel(e.target.value)}
              values={allHotels.map((data: any) => {
                return data.hotel_id;
              })}
              options={allHotels.map((data: any) => {
                return data.hotel_name;
              })}
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select Date"
              open={openDatePicker}
              onOpen={() => setOpenDatePicker(true)}
              onClose={() => setOpenDatePicker(false)}
              value={moment(firstDate)}
              onChange={onDateChange}
              inputFormat="dd MMM yyyy"
              disableMaskedInput
              renderInput={(params) => (
                <TextField
                  {...params}
                  onClick={() => setOpenDatePicker(true)}
                  onKeyDown={(e) => e.preventDefault()}
                />
              )}
            />
          </LocalizationProvider>
        </div>

        <Button handleClick={getInteractions}>Search</Button>
      </div>
      {/* </SidebarItemHeader> */}
      {userInteraction.length > 0 ? (
        <div className="bottom-container">
          <div className="small-card-container mt-4">
            {userInteraction
              .slice(
                (currentPageNo - 1) * selectPageSize,
                currentPageNo * selectPageSize
              )
              .map((data: any, i: any) => (
                <SmallCard
                  info={data}
                  key={i}
                  handleClick={() => openInteraction(data, i)}
                />
              ))}
          </div>

          <Pagination
            setCurrentPageNo={setCurrentPageNo}
            data={userInteraction}
            setSelectPageSize={setSelectPageSize}
            currentPageNo={currentPageNo}
            selectPageSize={selectPageSize}
          />
        </div>
      ) : (
        <div className="empty__room__div mt-5">
          <i className="bi bi-x-circle mb-3"></i>
          <div>No Interactions Available !</div>
        </div>
      )}

      {/* For Interaction Sliding Pane */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openInteractionTab}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setOpenInteractionTab(false);
        }}
      >
        <ChatInteraction
          selected_interaction={selectedInteraction}
          user_name={selectedUser}
          onClose={() => {
            setOpenInteractionTab(false);
          }}
        />
      </SlidingPane>

      {/* {openInteractionTab && (
        <>
         
          <div className="overlay activeOverlay"></div>
        </>
      )} */}
    </div>
  );
};

export default Interaction;
