import React, { useEffect } from "react";
import { LoginSuccess } from "../../redux/actions/LoginAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  AllProperties,
  CurrentProperty,
} from "../../redux/actions/PropertiesAction";
import kernelApi from "../../API/kernelApi";
import { LoginTypeAction } from "../../redux/actions/LoginTypeAction";

const Loginwithoutcredential = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let login_data: any = useParams();

  useEffect(() => {
    let send_login_data = {
      admin_id: atob(login_data.admin_id),
      auth_token: login_data.auth_token,
      company_id: atob(login_data.company_id),
      full_name: atob(login_data.full_name),
      hotel_id: atob(login_data.hotel_id),
      hotel_name: atob(login_data.hotel_name),
    };
    dispatch(LoginSuccess(send_login_data));
    dispatch(LoginTypeAction(true));
    navigate("/");
  }, [login_data]);
  return <></>;
};

export default Loginwithoutcredential;
