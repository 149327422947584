import React from "react";
import SidebarItemHeader from "../../views/headers/SidebarItemHeader";
import Close from "../../assets/img/Cancel.png";
import Mask from "../../assets/img/Mask.png";
import AgentMask from "../../assets/img/agent-mask.png";
import { UpdateSidebar } from "../../UtilityFunctions";

interface chat_interaction {
  selected_interaction: any;
  user_name: string;
  onClose: () => void;
}

const ChatInteraction: React.FC<chat_interaction> = ({
  selected_interaction,
  user_name,
  onClose,
}) => {
  console.log("selected_interaction", selected_interaction);
  return (
    <div>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Interaction Details</div>
      </div>
      <div className="height-64px mt-5"></div>
      {selected_interaction?.map((item: any, index: any) => {
        return (
          <div className="message-wrapper" key={index}>
            <div className="message-box">
              <div className="img-label">
                <img src={Mask} alt="mask" />
              </div>
              <div className="message">
                <div className="chat-name">{user_name}</div>
                <p>{item.question}</p>
              </div>
            </div>
            <div className="message-box">
              <div className="agent-message">
                <div className="chat-name">Jini Assist</div>

                <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
              </div>
              <div className="img-label">
                <img src={AgentMask} alt="mask" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatInteraction;
