import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import kernelApi from "../../API/kernelApi";
import { RootState } from "../../redux/store";

import ManagePropertyDropdown from "../manageproperty/ManagePropertyDropdown";

const AppHeader = () => {
  // data from redux
  const { current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { company_id, hotel_name, hotel_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const [hotelName, setHotelName] = useState<string>("");
  useEffect(() => {
    getHotelDetails();
  }, []);

  // Api To Get Hotel Name
  const getHotelDetails = async () => {
    try {
      let hotelRes = await kernelApi.get(
        `/extranetv4/superadmin-dashboard/retrive-details/${company_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (hotelRes.data.status === 1) {
        setHotelName(hotelRes.data.details[0].hotel_name);
      } else {
        setHotelName("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="header-wrapper header-bg-color header-position">
        <div className="header__content__wrapper">
          {/* <ManagePropertyDropdown />
          <h4 style={{ color: "red" }}>Hi mklnfwnfnefn ndsnfnl flknsdfn kflsdnfll  sdflskdnf </h4> */}
          <div
            className="hotel_name_label_jini_assist"
            // style={{ color: "blue", display: "flex", marginRight: "30px" }}
          >
            <div className="hotel__building__ico">
              <i className=" me-2 bi bi-building"></i>
            </div>
            <div>{hotel_name ? `${hotel_name}(${hotel_id})` : hotelName}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppHeader;
