export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GET_PROPERTIES = "GET_PROPERTIES";
export const CHOOSE_PROPERTY = "CHOOSE_PROPERTY";
export const ACTIVE_SIDEBAR_ITEM = "ACTIVE_SIDEBAR_ITEM";
export const ACTIVE_SIDEBAR_SUBITEM = "ACTIVE_SIDEBAR_SUBITEM";
export const UPDATE_BREADCRUMBS = "UPDATE_BREADCRUMBS";
export const ENABLE_CONFIRMATION_PROMPT = "ENABLE_CONFIRMATION_PROMPT";
export const CANCEL_CONFIRMATION_PROMPT = "CANCEL_CONFIRMATION_PROMPT";
export const ENABLE_SWITCH_PROPERTY_SCREEN = "ENABLE_SWITCH_PROPERTY_SCREEN";
export const DISABLE_SWITCH_PROPERTY_SCREEN = "DISABLE_SWITCH_PROPERTY_SCREEN";

export const DIRECT_LOGIN = "DIRECT_LOGIN";
