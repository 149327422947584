import React from "react";

// import mui components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export interface IInputSelectFieldProps {
  label: string;
  value: string | number | any;
  handleChange?: (e: any) => void;
  helperText?: string;
  options?: string[] | number[] ;
  values?: string[] | number[];
  disable?: boolean;
}

const InputSelectField: React.FC<IInputSelectFieldProps> = ({ label, value, disable, handleChange, options, values }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        onChange={handleChange}
        disabled={disable}>
        {options?.map((option, index) => {
          return (
            <MenuItem key={index} value={values ? values[index] : option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default InputSelectField;
