import React from "react";
interface cardD {
  info: {
    user_name: string;
    created_at: string;
  };
  handleClick?: () => void;
}
const SmallCard: React.FC<cardD> = ({ info, handleClick }) => {
  const { user_name, created_at } = info;
  return (
    <div className="small-card-wrapper" onClick={handleClick}>
      <div className="person-name">{user_name}</div>
      <div className="date-time">
        <div className="time">{created_at}</div>

        {/* <div className='time'>{created_at}</div>&nbsp;-&nbsp; */}
        {/* <div className='date'>{date}</div> */}
      </div>
    </div>
  );
};

export default SmallCard;
