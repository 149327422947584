import {
  ACTIVE_SIDEBAR_ITEM,
  ACTIVE_SIDEBAR_SUBITEM,
  UPDATE_BREADCRUMBS,
} from "../actionTypes";
import Sidebar_Menu from "../../components/sidebar/SidebarMenu.json";

const initialState = {
  active_sidebar_item: Sidebar_Menu[0].label,
  active_sidebar_subitem: "",
  breadcrumps: [],
};

const SidebarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        active_sidebar_item: action.payload,
      };
    case ACTIVE_SIDEBAR_SUBITEM:
      return {
        ...state,
        active_sidebar_subitem: action.payload,
      };
    case UPDATE_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    default:
      return state;
  }
};

export default SidebarReducer;
