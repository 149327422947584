import React from "react";
interface AppContentC {
  children: any;
}
const AppContent: React.FC<AppContentC> = ({ children }) => {
  return (
    <>
      <section>
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12 background--color"
                style={{ height: "100%" }}>
                <div className="height-86px"></div>
                <div className="main__content__wrapper">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppContent;
